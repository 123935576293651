body {
  margin: 0;
  padding: 0;
  position: relative;
}

#root {
    height: 100%;
}

.cascader-popup .ant-cascader-menu {
    height: auto;
    max-height: 20em;
}

.create-facility-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10;
}

.create-facility-popup > div {
    position: absolute;
    top: 50%;
    left: 50px;
    right: 50px;
    transform: translate(0, -50%);
    padding: 40px 0;
    background-color: white;
}

.ant-spin {
    width: 100%;
    margin-top: 200px;
}

.create-facility-popup .ant-spin {
    width: auto;
    margin-top: 10px;
}

.ant-spin-sm {
    width: auto;
    margin-top: 10px;
}

.ant-tooltip-inner a {
    color: white;
    font-weight: bold;
}

.machine-manuals > li {
    list-style: none;
    line-height: 3em;
}

.machine-manuals > li > a {
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    padding: 5px;
    margin-right: 10px;
}

.machine-manuals > li > a > span {
    color: black;
}

.machine-manuals > li > a:hover {
    border: 1px solid #b33b53;
}

.machine-manuals > li > a:hover > span {
    color: #b33b53;
}

